import React from 'react';

function HomePage() {
	return (
		<div className='flex flex-col text-center'>
			<p className='text-2xl text-white font-semibold mt-20 mb-8 p-2 uppercase'>
				One stop solution for organic fertilizer
			</p>
			<h3 className='md:text-2xl text-white md:p-40 p-5 text-lg'>
				Enables plants to fight from diseases and insects. It enhances the immune system of crops
				and also control diseases. It can be used for all crops like paddy, wheat, sugarcane,
				potato, soybean, ground nuts, peas, onion, and various types of vegetables, pulses and
				fruits etc.
			</h3>
		</div>
	);
}

export default HomePage;
