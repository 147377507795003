import React from 'react';

function Products() {
	return (
		<div>
			<p className='font-semibold text-2xl pt-24 md:p-60 text-white font-serif md:text-6xl'>
				coming soon ...
			</p>
		</div>
	);
}

export default Products;
