import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import Products from './pages/Products';
import img1 from '../src/images/8c1a33f4-2d5a-499e-b13f-09673e0b350d.jpeg';
import img2 from '../src/images/f568dded-7ca9-4e23-8f11-a8a952444362.jpeg';

function App() {
	return (
		<div
			className='backdrop:blur-xl'
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				// backgroundColor: 'white',
				height: '100vh',
				backgroundImage: `url(${img2})`,
				opacity: 0.9,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				// height: '500px' /* set the height of the div */,
			}}>
			<BrowserRouter>
				<Navbar />
				<Routes>
					<Route path='' Component={HomePage} />
					<Route path='/contact' Component={ContactUs} />
					<Route path='/product' Component={Products} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
