import React, {useState} from 'react';

function ContactUs() {
	const [responseMessage, setResponseMessage] = useState(false);
	const handleSubmit = () => {
		setResponseMessage(true);
		// setTimeout(() => {
		// 	setResponseMessage(false);
		// }, 3000);
	};
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '50%',
			}}>
			{responseMessage && (
				<p className='text-white font-semibold text-xl'>
					You response has been submitted we will get in touch with you soon
				</p>
			)}
			{!responseMessage && (
				<div className='flex w-72 flex-col gap-6'>
					<label
						for='number-input'
						class='block mb-2 text-sm font-medium text-gray-900 dark:text-black-500'>
						Enter your Email and Phone we will get back to you:
					</label>
					<input
						type='email'
						id='text-input'
						aria-describedby='helper-text-explanation'
						class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500'
						placeholder='email'
						required
					/>
					<input
						type='text'
						id='text-input'
						aria-describedby='helper-text-explanation'
						class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500'
						placeholder='phone'
						required
					/>
					<button
						className='bg-red-400 rounded-md w-20 text-white font-bold'
						onClick={handleSubmit}>
						Submit
					</button>
				</div>
			)}
		</div>
	);
}

export default ContactUs;
