import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import logo from '../images/logo-no-background.png';

function Navbar() {
	const [isOpenNav, setIsOpenNav] = useState(false);
	const handleOpenNav = () => {
		setIsOpenNav(!isOpenNav);
	};
	const handleCloseMenu = () => {
		setIsOpenNav(false);
	};
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				fontSize: '1.3rem',
				justifyContent: 'space-around',
				// paddingTop: '20px',
				marginTop: '1.3rem',
				alignItems: 'center',
				height: '5vh',
				fontFamily: 'serif',
			}}>
			<div
				className='flex flex-row pl-6 pr-6 justify-between md:justify-around items-center'
				style={{width: '100%'}}>
				<Link to='..'>
					<img src={logo} className='h-10' />
				</Link>
				<div className='text-white  hidden md:flex space-x-20'>
					<Link to='..' relative='path' className='hover:text-red-500'>
						About
					</Link>
					<Link to='/product' relative='path' className='hover:text-red-500'>
						Products
					</Link>
					<Link to='/contact' relative='path' className='hover:text-red-500'>
						Contact
					</Link>
				</div>
				<div className='md:hidden flex flex-col cursor-pointer' onClick={handleOpenNav}>
					<button className='text-white bottom-1 border w-5'></button>
					<button className='text-white bottom-1 border w-5 mt-1'></button>
					<button className='text-white bottom-1 border w-5 mt-1'></button>
				</div>
			</div>
			{isOpenNav && (
				<div
					className='text-black font-bold  border md:hidden flex flex-col backdrop-blur-sm bg-white/30 min-w-[100vw] items-center z-50 mt-20'
					onClick={handleCloseMenu}>
					<Link to='..' relative='path' className='hover:text-red-500'>
						About
					</Link>
					<Link to='/product' relative='path' className='hover:text-red-500'>
						Products
					</Link>
					<Link to='/contact' relative='path' className='hover:text-red-500'>
						Contact
					</Link>
				</div>
			)}
		</div>
	);
}

export default Navbar;
